<template>
    <!-- 通道管理》短信逃生管理(消息队列处理) -->
    <div id="app_queue_handle_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="prop_queue_handle_add_i ++" size="mini">
                    增加
                </el-button>
            </div>

            <div class="head_top_title">短信逃生列表</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height)+'px')}">
                <template v-for="item in tableData">

                    <el-descriptions  title="" :column="1" size="mini" border>

                        <el-descriptions-item label="提交时间" label-class-name="el_descriptions_label_class">
                            {{item.createtime}}
                        </el-descriptions-item>

                        <el-descriptions-item label="结束时间" label-class-name="el_descriptions_label_class">
                            {{item.updatetime}}
                        </el-descriptions-item>

                        <el-descriptions-item label="切换人员" label-class-name="el_descriptions_label_class">
                            {{item.username}}
                        </el-descriptions-item>

                        <el-descriptions-item label="拥堵通道" label-class-name="el_descriptions_label_class">
                            {{item.bad_channel_name}}
                        </el-descriptions-item>

                        <el-descriptions-item label="优先级" label-class-name="el_descriptions_label_class">
                            {{item.bad_send_type}}
                        </el-descriptions-item>

                        <el-descriptions-item label="目标通道" label-class-name="el_descriptions_label_class">
                            {{item.good_channel_name}}
                        </el-descriptions-item>

                        <el-descriptions-item label="优先级" label-class-name="el_descriptions_label_class">
                            {{item.good_send_type}}
                        </el-descriptions-item>

                        <el-descriptions-item label="速度" label-class-name="el_descriptions_label_class">
                            {{item.speed}}条/秒
                        </el-descriptions-item>

                        <el-descriptions-item label="超时时间" label-class-name="el_descriptions_label_class">
                            {{item.timeout}}秒
                        </el-descriptions-item>

                        <el-descriptions-item label="状态" label-class-name="el_descriptions_label_class">
                            {{item.channel_state}}
                        </el-descriptions-item>


                    </el-descriptions>

                    <el-divider style="background-color: #EE0909;margin: 5px 0;"></el-divider>
                    
                </template>

                <template v-if="tableData.length == 0">
                    <el-row type="flex" justify="center">无数据</el-row>
                </template>
                
            
            </el-row>

            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
            

        </el-row>

        
        

    <!-- 短信逃生增加组件 -->
    <ChannelQueueHandleAdd :prop_change_i="prop_queue_handle_add_i"
        @result="submit_cha_xun"
      ></ChannelQueueHandleAdd>


    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import ChannelQueueHandleAdd from './channel_queue_handle_add.vue';//组件 短信逃生增加

//import axios from "axios";
export default {

    components: {
        ChannelQueueHandleAdd,
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度


            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度


            //短信逃生增加
            prop_queue_handle_add_i:0,//短信逃生增加  组件 弹出框是否可见

          
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.submit_cha_xun();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },

        //查询
        submit_cha_xun(){

            //请求接口
            API.ChannelServlet({
                param: "selectChannelLog",
                indexPage:this.indexPage,
                pageSize:this.pageSize,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        

        //返回
        goBack() {
            API.router_to("/channel_index");
        }
    }
};

</script>

<style scoped>

#app_queue_handle_list>>>.el_descriptions_label_class{
    text-align: center;
    width: 80px;
    
}

/*form表单样式调整*/
#app_queue_handle_list>>>.el-divider {
  background-color: #409EFF;
  margin: 5px 0;
} 

</style>