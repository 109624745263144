<template>
<div>
    <!-- 通道管理》短信逃生管理(消息队列处理)》短信逃生 增加 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>增加短信逃生</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            
                <el-form  :model="form_data" label-width="90px" style="margin-bottom: 12px;">
                    <el-form-item label="故障通道">
                        <el-select v-model="form_data.bad_channel_id" filterable clearable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="优先级">
                        <el-select v-model="form_data.bad_send_type" size="mini" style="width:100%"> 
                            <el-option label="验证码" value="1"></el-option>
                            <el-option label="行业" value="2"></el-option>
                            <el-option label="营销" value="3"></el-option>
                            <el-option label="四类" value="4"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="目标通道">
                        <el-select v-model="form_data.good_channel_id" filterable clearable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="优先级">
                        <el-select v-model="form_data.good_send_type" size="mini" style="width:100%"> 
                            <el-option label="验证码" value="1"></el-option>
                            <el-option label="行业" value="2"></el-option>
                            <el-option label="营销" value="3"></el-option>
                            <el-option label="四类" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                     
                     <el-form-item label="速度" >
                        <el-input v-model="form_data.speeds" placeholder="请输入速度" size="mini" oninput="value=value.replace(/[^\d]/g,'')" style="vertical-align: middle;">
                            <template slot="append">条/秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="超时时间">
                        <el-input v-model="form_data.timeouts" placeholder="请输入超时时间" size="mini" oninput="value=value.replace(/[^\d]/g,'')" style="vertical-align: middle;">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            
        </el-row>
    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:0,//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取通道列表数据-请求接口获取
                    this.getChannelDataList();
                }, 1);
            }

            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            channelDataList:[],//通道列表数据-请求接口获取


            form_data:{
                bad_channel_id:"",//拥堵通道id
                good_channel_id:"",//目标通道id
                bad_send_type:"2",//拥堵通道的优先级
                good_send_type:"2",//目标通道的优先级
                speeds:"100",//速度(条/秒)
                timeouts:"10",//超时时间(秒)
            },
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },

        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },


        //提交
        onSubmit(){
            console.log("提交");


            if(API.isEmtry(this.form_data.bad_channel_id)){
                this.$message.error("请选择拥堵通道");
                return;
            }
            if(API.isEmtry(this.form_data.good_channel_id)){
                this.$message.error("请选择目标通道");
                return;
            }
            if(API.isEmtry(this.form_data.speeds)){
                this.$message.error("请填写速度");
                return;
            }
            if(API.isEmtry(this.form_data.timeouts)){
                this.$message.error("请填写超时时间");
                return;
            }
            
            API.ChannelServlet({
                param:'updateChannel',
                bad_channel_id:this.form_data.bad_channel_id,
                bad_channel_type:this.form_data.bad_send_type,
                good_channel_id:this.form_data.good_channel_id,
                good_channel_type:this.form_data.good_send_type,
                speed:this.form_data.speeds,
                timeout:this.form_data.timeouts,
            }).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>